/* eslint-disable no-redeclare,@typescript-eslint/no-explicit-any */

import { EventDetails, EventSummary } from 'backend/api-types/dashboard';

export type CommunicationMeta = {
  /** Email subject */
  subject?: string;

  /** File Name */
  title?: string;

  /** File MIME Type */
  type?: string;

  /** Communication Token */
  token?: string;

  /** Host IP addresss */
  ip?: string;
};

export type EventCommunicationMeta = CommunicationMeta & {
  /** Locator token for the communication */
  token?: string;

  /** Communication ID */
  communication?: number;

  /** The type of the communication */
  communicationType: CommunicationType;
};

type ClientFriendlyEventFields = {
  /** Information that be be used to find related events */
  relatedTo: string;

  /** Additional information about the event */
  meta: EventCommunicationMeta;
};

export type EventLogItem = EventSummary & ClientFriendlyEventFields;

export type EventLogDetails = EventDetails & ClientFriendlyEventFields;

enum CommunicationType {
  Unknown = 0,
  File = 1,
  Email = 2,
  Chat = 3,
  Form = 4,
  Data = 5,
  Gateway = 6,
  Vault = 7,
}
//
const emptyResponse = {
  meta: {
    communicationType: CommunicationType.Unknown,
  },
  relatedTo: '',
};
//
export function convertEventDataToMeta(initial?: string): {
  meta: EventCommunicationMeta;
  relatedTo: string;
} {
  if (!initial) {
    return emptyResponse;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let meta: any;
  try {
    meta = JSON.parse(initial);
  } catch (err) {
    // Must be a plain old string and not a stringified object
    meta = initial;
  }

  switch (typeof meta) {
    case 'object': {
      let type = CommunicationType.Unknown;
      if (meta?.title) {
        type = CommunicationType.File;
      } else if (meta?.subject) {
        type = CommunicationType.Email;
      }

      return {
        meta: {
          ...meta,
          communicationType: type,
        },
        relatedTo: meta.token || '',
      };
    }
    case 'string':
    case 'boolean':
    case 'number':
      return {
        meta: {
          communicationType: CommunicationType.Unknown,
        },
        relatedTo: String(meta),
      };
    default:
      return emptyResponse;
  }
}

export function convertEventToLogItem(ev: EventDetails): EventLogDetails;
export function convertEventToLogItem(ev: EventSummary): EventLogItem;
export function convertEventToLogItem(ev: any): any {
  return {
    ...ev,
    ...convertEventDataToMeta(ev.data),
  };
}
