import { createSlice } from '@reduxjs/toolkit';
import * as RulesEngine from 'json-rules-engine';

export interface RuleStructure {
  businessId?: number;
  content: {
    generatedRules: {
      conditions: Record<'any' | 'all', RulesEngine.ConditionProperties[]>;
      event: RulesEngine.Event;
      name: string;
    };
  };
  id: number;
  status: 'Active' | 'Inactive' | 'Warning';
  name: string;
  type: 'DataLoss' | 'DataAccess';
  warning?: boolean;
  refId: number;
  groups: number[];
}

export interface policyManagerSliceState {
  rules: RuleStructure[] | null;
}

const initialState: policyManagerSliceState = {
  rules: null,
};

const policyManagerSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    setRules(state, action) {
      const rules = action.payload;
      state.rules = rules;
    },
    resetRules() {
      return initialState;
    },
  },
});

export const { resetRules, setRules } = policyManagerSlice.actions;

export default policyManagerSlice.reducer;
