import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const LOCAL_STORAGE_KEY = 'encrypted-query-state';
const LOCAL_STORAGE_TTL = 60 * 60 * 1000; // 1 hour

export type MagicLinkQueryStoreValues = {
  /** The raw query string to store */
  query?: string;

  /** Expiration date, in milliseconds */
  expiration?: number;
};

export type MagicLinkQueryStoreContextValues = {
  /** The raw query string stored in local storage */
  returnTo: string;
  reset: () => void;
  update: (query: string) => void;
};

const MagicLinkQueryStoreContext =
  createContext<MagicLinkQueryStoreContextValues>({
    returnTo: '',
    update: () => {
      // Do nothing
    },
    reset: () => {
      // Do nothing
    },
  });

export const MagicLinkQueryStoreContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [state, setState] =
    useLocalStorageState<MagicLinkQueryStoreValues>(LOCAL_STORAGE_KEY);

  const update = useCallback(
    (query: string) => {
      setState({
        query,
        expiration: Date.now() + LOCAL_STORAGE_TTL,
      });
    },
    [setState]
  );

  const isExpired = state?.expiration && state.expiration < Date.now();
  const returnTo = isExpired ? '' : state?.query ?? '';

  // Reset the state if it is already expired
  useEffect(() => {
    if (isExpired) {
      setState.reset();
    }
  }, [isExpired, setState]);

  const values = useMemo(() => {
    return {
      returnTo,
      update,
      reset: setState.reset,
    };
  }, [returnTo, setState.reset, update]);

  return (
    <MagicLinkQueryStoreContext.Provider value={values}>
      {children}
    </MagicLinkQueryStoreContext.Provider>
  );
};

export default MagicLinkQueryStoreContext;
