import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';

import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from 'store';
import customChakraTheme from './theme/customChakraTheme';
import App from './App';
import { GlobalStoreContextProvider } from './global-context/GlobalStoreContext';
import { MagicLinkQueryStoreContextProvider } from './auth/MagicLinkQueryStoreContext';

const container = document.getElementById('root');
const root = createRoot(container!);

// TODO(worstestes - 9/22/22): ENABLE REACT.STRICTMODE, our upgrade to React v18 included a more strict version of this mode
root.render(
  <Provider store={store}>
    <GlobalStoreContextProvider>
      <ChakraProvider theme={customChakraTheme} resetCSS cssVarsRoot="body">
        <BrowserRouter>
          <HelmetProvider>
            <MagicLinkQueryStoreContextProvider>
              <App />
            </MagicLinkQueryStoreContextProvider>
          </HelmetProvider>
        </BrowserRouter>
      </ChakraProvider>
    </GlobalStoreContextProvider>
  </Provider>
);
