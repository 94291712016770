const root = '/contacts';
const baseRoute = (path = '') => `${root}${path}`;

export default {
  root,
  detail: {
    root: (id: number | string = ':contactId') => baseRoute(`/detail/${id}`),
    communications: (id: number | string = ':contactId') =>
      baseRoute(`/${id}/communications`),
  },
  create: baseRoute(`/create`),
  importFile: baseRoute('/import-file'),
} as const;
