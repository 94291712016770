export default {
  login: '/login',
  signup: '/signup',
  verify: '/signup/verify',
  accountVerified: '/signup/verify/success',
  code: '/login/code',
  forgotPassword: '/forgot-password',
  awsSubscribe: '/aws-subscribe',
  azureSubscribe: '/azure-subscribe',
  selectWorkspace: '/workspace',
} as const;
