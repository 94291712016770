import applicationRoutes from './application/routes';
import authRoutes from './auth/routes';
import contactRoutes from './contacts/routes';
import encryptionRoutes from './encryption/routes';
import eventLogRoutes from './event-log/routes';
import exportRoutes from './export/routes';
import keyPacketRoutes from './key-packet/routes';
import settingsRoutes from './settings/routes';
import teamRoutes from './team/routes';
import groupsRoutes from './groups/routes';
import tutorialRoutes from './tutorials/routes';
import unlockRoutes from './unlock/routes';
import welcomeRoutes from './welcome/routes';
import trustedRoutes from './trusted/routes';
import pricingRoutes from './pricing/routes';
import purchaseRoutes from './purchase/routes';
import workspaceRoutes from './workspace/routes';
import reportRoutes from './report/routes';
import securitySettingsRoutes from './security-settings/routes';

export default {
  home: '/',
  auth: authRoutes,
  application: applicationRoutes,
  tutorials: tutorialRoutes,
  about: '/about',
  eventLog: eventLogRoutes,
  settings: settingsRoutes,
  securitySettings: securitySettingsRoutes,
  contacts: contactRoutes,
  keyPacket: keyPacketRoutes,
  welcome: welcomeRoutes,
  team: teamRoutes,
  groups: groupsRoutes,
  encryption: encryptionRoutes,
  unlock: unlockRoutes,
  export: exportRoutes,
  trusted: trustedRoutes,
  pricing: pricingRoutes,
  purchase: purchaseRoutes,
  workspace: workspaceRoutes,
  report: reportRoutes,
} as const;
