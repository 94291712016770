// noinspection JSUnresolvedReference

import axios from 'axios';
import { env } from 'env';

const trimTrailingSlash = (url?: string) => url?.replace(/\/+$/, '');

export const timezoneOffset = -1 * (new Date().getTimezoneOffset() / 60);

// NOTE: Purposely using a strange syntax to specify the API keys. The reason for this
// is to avoid having an 'api-key' header set when the test cases are running. If
// on gets set on the test cases, we will need to explicitly mock out the OPTIONS requests
// as well as the request that we are interested in.
export const dashboardService = axios.create({
  baseURL: env.REACT_APP_DASHBOARD_HOST,
  headers: env.REACT_APP_DASHBOARD_API_KEY
    ? {
        'api-key': env.REACT_APP_DASHBOARD_API_KEY,
      }
    : {},
});

export const dashboardServiceBaseURL = trimTrailingSlash(
  dashboardService.defaults.baseURL
);

export const subscriptionService = axios.create({
  baseURL: env.REACT_APP_SUBSCRIPTION_HOST,
  headers: env.REACT_APP_FRAMEWORK_API_KEY
    ? {
        'api-key': env.REACT_APP_FRAMEWORK_API_KEY,
      }
    : {},
});

export const subscriptionServiceBaseURL = trimTrailingSlash(
  subscriptionService.defaults.baseURL
);

export const aliasSubscriptionService = axios.create({
  baseURL: env.REACT_APP_SUBSCRIPTION_HOST,
  headers: env.REACT_APP_FRAMEWORK_API_KEY
    ? {
        'api-key': env.REACT_APP_FRAMEWORK_API_KEY,
      }
    : {},
});

export const validationService = axios.create({
  baseURL: env.REACT_APP_VALIDATION_HOST,
  headers: env.REACT_APP_FRAMEWORK_API_KEY
    ? {
        'api-key': env.REACT_APP_FRAMEWORK_API_KEY,
      }
    : {},
});

export const aliasValidationService = axios.create({
  baseURL: env.REACT_APP_VALIDATION_HOST,
  headers: env.REACT_APP_FRAMEWORK_API_KEY
    ? {
        'api-key': env.REACT_APP_FRAMEWORK_API_KEY,
      }
    : {},
});

export const validationServiceBaseURL = trimTrailingSlash(
  validationService.defaults.baseURL
);

export const quantumService = axios.create({
  baseURL: env.REACT_APP_ENTROPY_HOST,
});

export const quantumServiceBaseURL = trimTrailingSlash(
  quantumService.defaults.baseURL
);

export function setAuthToken(token: string): void {
  const authHeader = `Bearer ${token}`;
  dashboardService.defaults.headers.Authorization = authHeader;
  subscriptionService.defaults.headers.Authorization = authHeader;
  validationService.defaults.headers.Authorization = authHeader;
}

export function removeAuthToken(): void {
  delete dashboardService.defaults.headers.Authorization;
  delete subscriptionService.defaults.headers.Authorization;
  delete validationService.defaults.headers.Authorization;
}
